<template>
  <b-alert
    show
    variant="info"
  >
    <b-spinner
      label="Loading"
      small
    />
    <span class="pl-2">Loading</span>
  </b-alert>
</template>

<script>
import {
  BAlert,
  BSpinner,
} from 'bootstrap-vue';

export default {
  components: {
    BAlert,
    BSpinner,
  },
};
</script>
