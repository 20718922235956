import Axios from 'axios';
import { ActionsUtility, GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const BASE_URL = `${process.env.VUE_APP_ROOT_API}/users`;

const ACTION_LOAD = 'Load';

const MUTATION_SET_DATA = 'SetData';
const MUTATION_SET_IS_LOADING = 'SetIsLoading';

const STATEVAR_DATA = 'data';
const STATEVAR_IS_LOADING = 'isLoading';

const actions = {
  Create: (context, payload) => new Promise((resolve, reject) => {
    const options = {
      actionVerb: 'post',
      actionUrl: `${BASE_URL}`,
      context,
      dataPayload: payload,
      loadAction: ACTION_LOAD,
      loadingMutation: MUTATION_SET_IS_LOADING,
      resolve,
      reject,
    };
    ActionsUtility.reloadAffectingAction(options);
  }),
  Delete: (context, payload) => new Promise((resolve, reject) => {
    const options = {
      actionVerb: 'delete',
      // to confirm endpoint
      actionUrl: `${BASE_URL}/${payload.id}`,
      context,
      dataPayload: payload,
      loadAction: ACTION_LOAD,
      loadingMutation: MUTATION_SET_IS_LOADING,
      resolve,
      reject,
    };
    ActionsUtility.reloadAffectingAction(options);
  }),
  Edit: (context, payload) => new Promise((resolve, reject) => {
    const options = {
      actionVerb: 'put',
      // to confirm endpoint
      actionUrl: `${BASE_URL}/${payload.id}`,
      context,
      dataPayload: payload,
      loadAction: ACTION_LOAD,
      loadingMutation: MUTATION_SET_IS_LOADING,
      resolve,
      reject,
    };
    ActionsUtility.reloadAffectingAction(options);
  }),
  Load: (context) => new Promise((resolve, reject) => {
    context.commit(MUTATION_SET_IS_LOADING, true);
    context.commit(MUTATION_SET_DATA, []);
    const request = {
      url: BASE_URL,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    Axios(request)
      .then((response) => {
        context.commit(MUTATION_SET_DATA, response.data.result);
        resolve();
      })
      .catch(() => {
        reject(new Error('Could not load users'));
      })
      .finally(() => {
        context.commit(MUTATION_SET_IS_LOADING, false);
      });
  }),
  ToggleStatus: (context, payload) => new Promise((resolve, reject) => {
    ActionsUtility.reloadAffectingAction({
      actionVerb: 'put',
      actionUrl: `${BASE_URL}/${payload.id}/account?enable=${payload.disabled}`,
      context,
      loadAction: ACTION_LOAD,
      loadingMutation: MUTATION_SET_IS_LOADING,
      reject,
      resolve,
    });
  }),

};
const getters = {
  Data: GettersUtility.getArrayCopy(STATEVAR_DATA),
  IsLoading: GettersUtility.get(STATEVAR_IS_LOADING),
};

const mutations = {
  SetData: MutationsUtility.set(STATEVAR_DATA),
  SetIsLoading: MutationsUtility.set(STATEVAR_IS_LOADING),
};

const state = {
  [STATEVAR_DATA]: [],
  [STATEVAR_IS_LOADING]: false,
};
export default {
  actions,
  getters,
  mutations,
  state,
};
