<template>
  <b-badge
    pill
    :variant="variant"
  >
    {{ active | status }}
  </b-badge>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue';

export default {
  components: {
    BBadge,
  },
  computed: {
    variant() {
      return this.active ? 'success' : 'danger';
    },
  },
  filters: {
    status(active) {
      return active ? 'Enabled' : 'Disabled';
    },
  },
  props: {
    active: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
