<template>
  <ag-grid
    :fields="headerNames"
    :is-busy="isLoading"
    is-responsive
    :values="valuesData"
    :table-id="tableId"
  >
    <template #cell(disabled)="row">
      <grid-row-status :active="!row.item.disabled" />
    </template>
    <template v-slot:cell(actions)="row">
      <b-button-group size="sm">
        <b-button
          @click.prevent="gridActionClicked(formStates.edit, row.item)"
          title="Edit"
          variant="outline-primary"
        >
          <b-icon-pencil-square />
        </b-button>
        <b-button
          @click="gridActionClicked(formStates.toggleStatus, row.item)"
          :title="row.item.disabled ? 'Enable': 'Disable'"
          variant="outline-danger"
        >
          <b-icon-person-check v-if="row.item.disabled" />
          <b-icon-person-x v-else />
        </b-button>
      </b-button-group>
    </template>
  </ag-grid>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  BButton,
  BButtonGroup,
  BIconPencilSquare,
  BIconPersonX,
  BIconPersonCheck,
} from 'bootstrap-vue';

import AgGrid from '@afrigis/bootstrap-vue-table';
import GridRowStatus from '@/components/shared/GridRowStatus.vue';

export default {
  components: {
    AgGrid,
    BButton,
    BButtonGroup,
    BIconPersonX,
    BIconPersonCheck,
    BIconPencilSquare,
    GridRowStatus,
  },
  computed: {
    ...mapGetters({
      IsLoading: 'userManagementStore/IsLoading',
      Users: 'userManagementStore/Data',
    }),
    headerNames() {
      return [
        {
          key: 'contactNo',
          label: 'Contact No',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'surname',
          label: 'Surname',
          sortable: true,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true,
        },
        {
          key: 'disabled',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
        },
      ];
    },
    isLoading() {
      return this.IsLoading;
    },
    tableId: () => 'usersTable',
  },
  data() {
    return {
      valuesData: this.values,
    };
  },
  methods: {
    gridActionClicked(formState, row) {
      this.$emit('grid-action-clicked', { formState, row });
    },
  },
  name: 'UserGrid',
  props: {
    formStates: {
      required: true,
      type: Object,
    },
    values: {
      required: true,
      type: Array,
    },
  },
  watch: {
    values(newV) {
      this.valuesData = newV;
    },
  },
};
</script>

<style>

</style>
