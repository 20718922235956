<template>
  <b-container fluid>
    <b-row class="pt-2">
      <h1 class="col-12 col-md-8">
        User Management
      </h1>
      <b-col class="text-left text-md-right" cols="12" md="4">
        <b-button-group>
          <filter-dropdown :filtered="isBeingFiltered">
            <b-form-checkbox v-model="filterOnEnabled">
              Only show Enabled Users
            </b-form-checkbox>
          </filter-dropdown>
          <b-button @click="setupModifyModal(formStates.create, null)" variant="primary">
            Create
          </b-button>
          <export-to-csv :data="exportData" :file-name="fullFileName" />
        </b-button-group>
      </b-col>
    </b-row>
    <b-alert
      @dismissed="alertDismissed"
      class="text-center"
      dismissible
      variant="danger"
      :show="isShowingLoaderErrorAlert"
    >
      {{ loadingErrorMessage }}
    </b-alert>
    <grid :formStates="formStates" @grid-action-clicked="gridActionHandler" :values="tableValues" />
    <crud-modal
      @close-modal="closeModal"
      :form-state="formState"
      :form-states="formStates"
      :modal-id="modifyModalId"
      :is-loading="isLoading"
      :is-submit-disabled="isSubmitDisabled"
      :notification="notification"
      :payload="payload"
      @submit="submitHandler"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  BAlert,
  BCol,
  BContainer,
  BButton,
  BButtonGroup,
  BFormCheckbox,
  BRow,
} from 'bootstrap-vue';

import CrudModal from '@/components/users/CrudModal.vue';
import exportDataBase from '@/mixins/exportData';
import ExportToCsv from '@/components/shared/ExportToCsv.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';
import httpErrorHandler from '@/mixins/httpErrorHandler';
import Grid from '@/components/users/Grid.vue';
import modifyModalBase from '@/mixins/modifyModal';

export default {
  components: {
    BAlert,
    BCol,
    BContainer,
    BButton,
    BButtonGroup,
    BFormCheckbox,
    BRow,
    CrudModal,
    ExportToCsv,
    FilterDropdown,
    Grid,
  },
  computed: {
    ...mapGetters({
      IsLoading: 'userManagementStore/IsLoading',
      Users: 'userManagementStore/Data',
    }),
    emptyPayload: () => ({
      disabled: false,
      email: '',
      name: '',
      surname: '',
      contactNo: '',
      role: null,
    }),
    exportData() {
      if (this.IsLoading || !this.Users || !this.Users.length) {
        return [];
      }
      return this.tableValues.map((u) => ({
        ID: u.id,
        Email: u.email,
        Name: u.name,
        Surname: u.surname,
        Contact: u.contactNo,
      }));
    },
    fileNamePrefix: () => 'user-management',
    formStates() {
      return {
        create: {
          actionButtonText: 'Create',
          actionButtonVariant: 'primary',
          cancelButtonText: 'Cancel',
          headingText: 'Create',
          submitMethod: this.Create,
        },
        edit: {
          actionButtonText: 'Edit',
          actionButtonVariant: 'primary',
          cancelButtonText: 'Cancel',
          headingText: 'Edit',
          submitMethod: this.Edit,
        },
        toggleStatus: {
          actionButtonText: 'Yes',
          actionButtonVariant: 'danger',
          cancelButtonText: 'No',
          headingText: 'Toggle User Status',
          submitMethod: this.ToggleStatus,
        },
      };
    },
    isBeingFiltered() {
      return this.filterOnEnabled;
    },
    isLoading() {
      return this.IsLoading;
    },
    tableValues() {
      if (!this.Users) {
        return [];
      }
      return this.Users
        .filter((u) => !this.filterOnEnabled || !u.disabled);
    },
  },
  data() {
    return {
      filterOnEnabled: false,
      filterOnOrganisations: null,
    };
  },
  methods: {
    ...mapActions({
      Create: 'userManagementStore/Create',
      Edit: 'userManagementStore/Edit',
      LoadData: 'userManagementStore/Load',
      ToggleStatus: 'userManagementStore/ToggleStatus',
    }),
    gridActionHandler(payload) {
      const { formState, row } = payload;
      this.setupModifyModal(formState, row);
    },
    submitHandler(payload) {
      this.payload = payload;
      this.submit();
    },
  },
  mixins: [exportDataBase, httpErrorHandler, modifyModalBase],
  mounted() {
    this.LoadData()
      .catch(this.handleError);
  },
  name: 'UserManagement',
};
</script>

<style></style>
