<template>
  <validation-observer v-slot="{ handleSubmit, invalid }">
    <form-modal
      :action-button-text="formState.actionButtonText"
      :action-button-variant="formState.actionButtonVariant"
      :cancel-button-text="formState.cancelButtonText"
      @closed="$emit('close-modal')"
      :disable-submit="IsLoading || invalid"
      :heading-text="formState.headingText"
      :loading="IsLoading"
      :modal-id="modalId"
      :notification-message="notification.message"
      :notification-type="notification.type"
      @submitted="handleSubmit(submit)"
    >
      <p v-if="formStates.delete === formState">
        Are you sure you want to delete "{{ localPayload.email }}"?
      </p>
      <p v-else-if="formStates.toggleStatus === formState">
        {{ payloadToggleStatusText }}
      </p>
      <slot v-else-if="[formStates.create, formStates.edit].includes(formState)">
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
          >
            <float-label>
              <b-form-input
                name="email"
                placeholder="Email"
                type="email"
                v-model="localPayload.email"
              />
            </float-label>
            <validation-error-label :error="errors[0]" />
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <float-label>
              <b-form-input
                name="name"
                placeholder="Name"
                type="text"
                v-model="localPayload.name"
              />
            </float-label>
            <validation-error-label :error="errors[0]" />
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <float-label>
              <b-form-input
                name="surname"
                placeholder="Surname"
                type="text"
                v-model="localPayload.surname"
              />
            </float-label>
            <validation-error-label :error="errors[0]" />
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            rules="required|contactNo"
          >
            <float-label>
              <b-form-input
                name="contactNo"
                placeholder="Contact Number"
                type="text"
                v-model="localPayload.contactNo"
              />
            </float-label>
            <validation-error-label :error="errors[0]" />
          </validation-provider>
        </b-form-group>
        <b-form-group>
            <validation-provider v-slot="{ errors }" rules="required">
              <float-label :dispatch="false" label="Role">
                <b-form-select
                  :options="allowedRoles"
                  v-model="localPayload.role"
                  name="role"
                >
                  <template v-slot:first>
                    <option disabled selected :value="null">-- Select Role --</option>
                  </template>
                </b-form-select>
              </float-label>
              <validation-error-label :error="errors[0]" />
            </validation-provider>
          </b-form-group>
      </slot>
    </form-modal>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

import { email, required } from 'vee-validate/dist/rules';

import {
  BFormSelect,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';

import FloatLabel from 'vue-float-label/components/FloatLabel.vue';
import FormModal from '@/components/shared/FormModal.vue';
import ValidationErrorLabel from '@/components/shared/ValidationErrorLabel.vue';

extend('email', {
  ...email,
  message: 'Email address invalid',
});
extend('required', {
  ...required,
  message: 'This field is required',
});

extend('contactNo', {
  validate: (value) => {
    const phoneNumber = /^(0)(\d{2})-?(\d{3})-?(\d{4}|\d{8})$/;
    return !!phoneNumber.test(value);
  },
  message: 'Phone number is invalid',
});

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    FloatLabel,
    FormModal,
    ValidationErrorLabel,
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      IsLoading: 'userManagementStore/IsLoading',
      RolesDictionary: 'rolesStore/Dictionary',
      RolesList: 'rolesStore/List',
    }),
    allowedRoles() {
      if (!this.UserRole) {
        return [];
      }
      const allowedRoleValues = this.RolesDictionary[this.UserRole].allowedToCreateRoles;
      return this.RolesList
        .filter((r) => allowedRoleValues.some((arv) => arv === r.value))
        .map((r) => ({
          text: r.display,
          value: r.value,
        }));
    },
    organisationList() {
      // Todo-get organisations from Organisation store
      return ['Org1', 'Org2'];
    },
    payloadToggleStatusText() {
      const action = this.localPayload.disabled ? 'Enable' : 'Disable';
      return `Are you sure you want to ${action} User "${this.localPayload.email}"?`;
    },
    UserRole() {
      // Todo-get role from userconfig store
      return 'SuperAdministrator';
    },
  },
  data() {
    return {
      localPayload: {},
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.localPayload);
    },
  },
  mounted() {
    this.localPayload = this.payload;
  },
  props: {
    notification: {
      required: true,
      type: Object,
    },
    formState: {
      required: true,
      type: Object,
    },
    formStates: {
      required: true,
      type: Object,
    },
    modalId: {
      required: true,
      type: String,
    },
    payload: {
      required: true,
      type: Object,
    },
  },
  watch: {
    payload(newPayload) {
      this.localPayload = newPayload;
    },
  },
};
</script>

<style>

</style>
