<template>
  <b-dropdown
    dropleft
    title="Filters"
    variant="secondary"
  >
    <template #button-content>
      <b-icon-funnel-fill v-if="filtered" />
      <b-icon-funnel v-else />
    </template>
    <b-dropdown-header>
      <h2 class="h5">
        Filters
      </h2>
    </b-dropdown-header>
    <b-dropdown-form>
      <slot />
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BDropdownHeader,
  BIconFunnel,
  BIconFunnelFill,
} from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BDropdownForm,
    BDropdownHeader,
    BIconFunnel,
    BIconFunnelFill,
  },
  props: {
    filtered: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style>
  .dropdown-menu {
    width: 400px;
  }
</style>
