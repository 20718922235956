export default {
  computed: {
    isShowingLoaderErrorAlert() {
      return !!this.loadingErrorMessage;
    },
  },
  data() {
    return {
      loadingErrorMessage: null,
    };
  },
  methods: {
    alertDismissed() {
      this.loadingErrorMessage = null;
    },
    handleError(error) {
      this.loadingErrorMessage = error.message;
    },
  },
};
