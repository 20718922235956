export default {
  computed: {
    exportData: () => [{ exportDataNotImplemented: 'true' }],
    fileNamePrefix: () => '',
    fullFileName() {
      const today = new Date();
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return `${this.fileNamePrefix}-${today.toLocaleDateString('en-ZA', options)}`;
    },
  },
};
