export default {
  computed: {
    formStates() {
      return {
        create: {
          headingText: 'Create',
          submitMethod: null,
        },
      };
    },
    modifyModalId() {
      return `modifyModal-${Math.random()}`;
    },
  },
  created() {
    this.formState = this.formStates.create;
  },
  data() {
    return {
      formState: null,
      notification: {
        message: '',
        type: 'alert-danger',
      },
      payload: {},
    };
  },
  methods: {
    closeModal() {
      this.notification.message = '';
      this.$bvModal.hide(this.modifyModalId);
    },
    setupModifyModal(formState, payload) {
      if (!payload) {
        this.payload = { ...this.emptyPayload };
      } else {
        this.payload = { ...payload };
      }
      this.formState = formState;
      this.$bvModal.show(this.modifyModalId);
    },
    submit() {
      this.notification.message = '';
      this.formState.submitMethod(this.payload)
        .then(() => {
          this.$bvModal.hide(this.modifyModalId);
        })
        .catch((error) => {
          if (!error) {
            this.notification.message = 'An error occurred';
            return;
          }

          const message = (error
            && error.response
            && error.response.data
            && error.response.data.message)
            ? error.response.data.message
            : error.toString();
          this.notification.message = message;
        });
    },
  },
};
